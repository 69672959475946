import React from "react";
import { Nav } from "react-bootstrap";
import { approvedUsersPath, trialRequestedUsersPath } from "../const/routes";

function Sidebar() {
  return (
    <>
      <Nav className="col-md-12 d-none d-md-block bg-light sidebar">
        <div className="sidebar-sticky"></div>
        <Nav.Item>
          <Nav.Link href={approvedUsersPath}>Approved Users</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href={trialRequestedUsersPath}>
            Trial Requested Users
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}
export default Sidebar;
