import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isRight } from "fp-ts/lib/Either";
import { none } from "fp-ts/lib/Option";
import { authFetch } from "../services/Fetch";
import { ProductInfo, ProductInfoListResponse } from "../shared/responses";
import {
  ErrorResponse,
  HttpCode
} from "../shared/types";
import { PageState } from "./models";

export const fetchProductInfoList = createAsyncThunk<
  Array<ProductInfo>,
  // First argument to the payload creator
  void,
  // Types for ThunkAPI
  {
    rejectValue: ErrorResponse;
  }
>("/account/products", async (_, thunkAPI) => {
  let result = await authFetch<ProductInfoListResponse>({
    url: "api/v1/user/products",
    method: "get",
    bodyData: none,
  });

  if (isRight(result)) {
    return result.right.data;
  }
  return thunkAPI.rejectWithValue(result.left);
});

// Define a type for the slice state
interface UserAccountInfoState {
  pageState: PageState;
  applicaitonInfoList: Array<ProductInfo>;
  isAuthorized: boolean;
}

// Define the initial state using that type
const initialState: UserAccountInfoState = {
  isAuthorized: true,
  pageState: { errorMessage: "", isSuccess: false, isError: false, isLoading: false },
  applicaitonInfoList: [],
};

export const productsInfoSlice = createSlice({
  name: "productInfos",
  initialState,
  reducers: {
    clearState: (state) => {
      state.pageState.isError = false;
      state.pageState.isSuccess = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductInfoList.fulfilled, (state, { payload }) => {
      state.pageState.isLoading = false;
      state.pageState.isSuccess = true;
      state.isAuthorized = true;
      state.pageState.isError = false;
      state.applicaitonInfoList = payload;

      console.log("fulfill?", payload)

    });

    builder.addCase(fetchProductInfoList.rejected, (state, { payload }) => {
      state.pageState.isError = true;
      state.pageState.isSuccess = false;
      state.pageState.isLoading = false;
      state.pageState.errorMessage = payload ? payload.errorMessage : "Encountered Error";

      if (payload?.httpCode! === HttpCode.UNAUTHORIZED) {
        state.isAuthorized = false;
      }
    });

    builder.addCase(fetchProductInfoList.pending, (state, _action) => {
      state.pageState.isLoading = true;
    });
  },
});

export const { clearState } = productsInfoSlice.actions;
export default productsInfoSlice.reducer;
