import { faCalendar, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isSome } from "fp-ts/lib/Option";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import Sidebar from "../components/Dashboard";
import InfoModal from "../components/InfoModal";
import CalandarModal from "../components/modals/CalandarModal";
import LicenseTypeModal from "../components/modals/LicenseTypeModal";
import { CurrentModalUserWithLicense } from "../components/modals/models";
import NavTabs from "../components/NavBar";
import { loginPath } from "../const/routes";
import { ChangeLicenseRequest, ScaToolKitLicenseType } from "../domain/models";
import { fromDateToTimestamp } from "../domain/value_objects/value_object";
import { fetchApprovedUsers } from "../redux/ApprovedUsersSlice";
import { RootState } from "../redux/store";
import { changeLicense as updateLicense } from "../redux/UpdateLicenseSlice";
import { removeTokens } from "../services/LocalStroage";


export function ApprovedUsersPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageState = useSelector((state: RootState) => state.approvedUserReducer.pageState);
  const isAuthorized = useSelector((state: RootState) => state.approvedUserReducer.isAuthorized);

  const changeLicenseTypePageState = useSelector((state: RootState) => state.changeLicenseTypeReducer.pageState);

  const users = useSelector((state: RootState) => state.approvedUserReducer.trialRequestedUsers);
  const userBasicInfoOrNone = useSelector((state: RootState) => state.changeLicenseTypeReducer.userBasicInfoOrNone);

  const [showModel, setShowModal] = useState<boolean>(false);
  const [showInfoModel, setShowInfoModal] = useState<boolean>(false);
  const [showCalandarModal, setShowCalandarModal] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [currentModalUser, setCurrentModalUserLicense] = useState<CurrentModalUserWithLicense>({
    name: "",
    email: "",
    licenseInfo: { licenseId: "", data: {} },
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchApprovedUsers());

    if (!isAuthorized) {
      removeTokens();
      navigate(loginPath, { replace: true });
    }

    if (pageState.isError) {
      toast.error(pageState.errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
    }
  }, [isAuthorized, pageState.isError, pageState.errorMessage, dispatch, navigate]);

  useEffect(() => {
    if (!isAuthorized) {
      removeTokens();
      navigate(loginPath, { replace: true });
    }

    if (changeLicenseTypePageState.isError) {
      toast.error(changeLicenseTypePageState.errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
    }
  }, [isAuthorized, changeLicenseTypePageState.isError, changeLicenseTypePageState.errorMessage, dispatch, navigate]);

  useEffect(() => {
    if (isSome(userBasicInfoOrNone)) {
      setDialogMessage(`License has been changed successfully for ${userBasicInfoOrNone.value.display_name}.`);
      setShowInfoModal(true);
    }
  }, [userBasicInfoOrNone]);

  useEffect(() => {
    setLoading(pageState.isLoading || changeLicenseTypePageState.isLoading);
  }, [pageState.isLoading, changeLicenseTypePageState.isLoading]);

  return (
    <>
      <NavTabs />
      <Container fluid>
        <Row>
          <Col xs={2} id="sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col xs={10} id="page-content-wrapper">
            <Toaster />

            <InfoModal
              showModel={showInfoModel}
              info={dialogMessage}
              onClose={() => {
                setShowInfoModal(false);
                dispatch(fetchApprovedUsers());
              }}
              onSubmit={(value: ChangeLicenseRequest) => {
                console.log("value", value);
                setShowInfoModal(false);
              }}
            />

            <CalandarModal
              //add key so that modal state are independent each other
              // added 'c' so that it is different from license type modal
              key={currentModalUser.licenseInfo.licenseId + "c"}
              user={currentModalUser}
              showModel={showCalandarModal}
              onClose={() => {
                setShowCalandarModal(false);
              }}
              onSubmit={(value: ChangeLicenseRequest) => {
                console.log("value", value);
                setShowCalandarModal(false);
                dispatch(updateLicense(value));
              }}
            />

            <LicenseTypeModal
              key={currentModalUser.licenseInfo.licenseId + "t"} //add key so that modal state are independent each other //https://stackoverflow.com/questions/54895883/reset-to-initial-state-with-react-hooks
              user={currentModalUser}
              showModel={showModel}
              onClose={() => {
                setShowModal(false);
              }}
              onSubmit={(value: ChangeLicenseRequest) => {
                console.log("value", value);
                setShowModal(false);
                dispatch(updateLicense(value));
              }}
            />
            {isLoading ? (
              <Container>
                <Row className="justify-content-md-center">
                  <Col xs lg="2"></Col>
                  <Col md="auto">
                    <Container fluid className="py-5">
                      <PulseLoader loading={isLoading} color="#9B9B9B" />
                    </Container>
                  </Col>
                  <Col xs lg="2"></Col>
                </Row>
              </Container>
            ) : (
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>License Type</th>
                    <th>Created At</th>
                    <th>Expired At</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(function (user, index) {
                    return (
                      <tr key={index}>
                        <td>{index}</td>
                        <td>{user.user_basic_info.display_name}</td>
                        <td>{user.user_basic_info.email}</td>
                        <td>{user.license_type}</td>
                        <td>{user.created_at}</td>
                        <td>{user.expired_at}</td>
                        <td>
                          <div>
                            <Button variant="outline-primary">
                              <FontAwesomeIcon
                                icon={faCalendar}
                                size="lg"
                                onClick={() => {
                                  setCurrentModalUserLicense({
                                    name: user.user_basic_info.display_name,
                                    email: user.user_basic_info.email,
                                    licenseInfo: {
                                      licenseId: user.license_id,
                                      data: { expired_at: fromDateToTimestamp(new Date()) },
                                    },
                                  });
                                  setShowCalandarModal(true);
                                }}
                              />
                            </Button>
                            <Button variant="outline-primary">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                size="lg"
                                onClick={() => {
                                  setCurrentModalUserLicense({
                                    name: user.user_basic_info.display_name,
                                    email: user.user_basic_info.email,
                                    licenseInfo: {
                                      licenseId: user.license_id,
                                      data: { license_type: user.license_type as ScaToolKitLicenseType },
                                    },
                                  });
                                  setShowModal(true);
                                }}
                              />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
