import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ChangeLicenseRequest, ScaToolKitLicenseEnum, ScaToolKitLicenseType } from "../../domain/models";
import { CurrentModalUserWithLicense } from "./models";

type LicenseModalProps = {
  showModel: boolean;
  user: CurrentModalUserWithLicense;
  onClose: () => void;
  onSubmit: (values: ChangeLicenseRequest) => void;
};

function LicenseTypeModal(prop: LicenseModalProps) {
  const [currentLicenseType, setCurrentLicenseType] = useState<ScaToolKitLicenseType>(
    prop.user.licenseInfo.data.license_type!
  );

  return (
    <>
      <Modal show={prop.showModel} onHide={prop.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change License Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name : {prop.user.name} </Form.Label>
              <br />
              <Form.Label>Email : {prop.user.email} </Form.Label>
            </Form.Group>

            <Form.Select
              defaultValue={currentLicenseType}
              aria-label="Default select example"
              onChange={(e) => setCurrentLicenseType(e.target.value as ScaToolKitLicenseType)}
            >
              {Object.keys(ScaToolKitLicenseEnum).map((value, key) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={prop.onClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              prop.onSubmit({
                licenseId: prop.user.licenseInfo.licenseId,
                data: { license_type: currentLicenseType as ScaToolKitLicenseType },
              });
            }}
          >
            Change License
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default LicenseTypeModal;
