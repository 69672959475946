import { Option } from "fp-ts/Option";
import { RedirectWithData } from "../../domain/models";

export interface LoginInfo {
  email: string;
  password: string;
}

export interface Token {
  access_token: string;
}

//ref: https://stackoverflow.com/questions/40227401/const-enum-in-typescript
// remove `const` due to that
export enum HttpCode {
  OK = 200,
  CONTENT_NOT_FOUND = 204,
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,
  SERVER_ERROR = 500,
  NOT_FOUND = 404,
  FORBIDDEN = 403,
  CONFLICT = 409,
}

export interface ErrorResponse {
  errorMessage: string;
  httpCode: HttpCode;
}

export interface ErrorResponseWithRedirectPath extends ErrorResponse {
  redirectWithData: Option<RedirectWithData>;
}

export interface FetchParameter {
  url: string;
  method: string;
  bodyData: Option<any>;
}

