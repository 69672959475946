import { Option } from "fp-ts/Option";
import { timestamp } from "../value_objects/value_object";

export interface RedirectWithData {
  data: Option<any>;
  redirectTo: Option<string>;
}

export interface EmailAddress {
  value: string;
}

export type ScaToolKitLicenseType = 'BASIC' | 'ADVANCED' | 'PROFESSIONAL';

export interface LicenseApprovalRequest {
  license_type: ScaToolKitLicenseType;
  is_approved: boolean;
  license_id: string;
}

export type AppId = 'sca_toolkit' | 'async_toolkit';

export enum ScaToolKitLicenseEnum {
  BASIC = "BASIC",
  ADVANCED = "ADVANCED",
  PROFESSIONAL = "PROFESSIONAL"
}

export type LicenseId = string;


export type LicenseUpdateRequest = {
  license_type?: ScaToolKitLicenseType;
  expired_at?: timestamp;
}


export type ChangeLicenseRequest = {
  licenseId: LicenseId;
  data: LicenseUpdateRequest;
}



export type ChangeLicenseExpirationRequest = {
  licenseId: LicenseId;
  expirationDate: Date;
}
