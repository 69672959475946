import { Navigate } from "react-router-dom";
import { loginPath } from "../const/routes";

interface Props {
  component: React.ComponentType;
  path?: string;
}

//info: https://medium.com/front-end-weekly/how-to-create-private-route-with-react-router-v6-fa4b9d74cb55

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  let token = localStorage.getItem("access_token");

  if (token) {
    return <RouteComponent />;
  } else {
    return <Navigate to={loginPath} replace/>;
  }
};
