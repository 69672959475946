import { isSome } from "fp-ts/lib/Option";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import Sidebar from "../components/Dashboard";
import NavTabs from "../components/NavBar";
import { loginPath } from "../const/routes";
import { ScaToolKitLicenseType } from "../domain/models";
import { RootState } from "../redux/store";
import {
  approveTrialRequestedUser,
  fetchTrialRequestedUsers
} from "../redux/TrialRequestedUsersSlice";
import { removeTokens } from "../services/LocalStroage";

export function TrialRequestedUsersPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageState = useSelector(
    (state: RootState) => state.trialRequestedUsersReducer.pageState
  );
  const isAuthorized = useSelector(
    (state: RootState) => state.trialRequestedUsersReducer.isAuthorized
  );

  const trialRequestedUsers = useSelector(
    (state: RootState) => state.trialRequestedUsersReducer.trialRequestedUsers
  );

  const trialApprovalState = useSelector(
    (state: RootState) => state.trialRequestedUsersReducer.trialApprovalState
  );

  const [showModel, setShowModel] = useState(false);
  const [approvedUserEmail, setApprovedUserEmail] = useState("");

  useEffect(() => {
    dispatch(fetchTrialRequestedUsers());

    if (!isAuthorized) {
      removeTokens();
      navigate(loginPath, { replace: true });
    }

    if (pageState.isError) {
      toast.error(pageState.errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
    }

    if (isSome(trialApprovalState)) {
      setShowModel(isSome(trialApprovalState));
    }
  }, [isAuthorized, pageState.isSuccess, dispatch]);

  useEffect(() => {
    if (isSome(trialApprovalState)) {
      setApprovedUserEmail(trialApprovalState.value.email);
      console.log("approved", trialApprovalState.value);
      setShowModel(isSome(trialApprovalState));
    }
  }, [trialApprovalState]);

  function onApproved(licenseId: string) {
    let licenseType: ScaToolKitLicenseType = "BASIC" 
    dispatch(approveTrialRequestedUser({ license_id: licenseId, license_type: licenseType, is_approved: true }));
  }

  const handleClose = () => {
    setShowModel(false);
    dispatch(fetchTrialRequestedUsers());
  };

  return (
    <>
      <NavTabs />
      <Container fluid>
        <Row>
          <Col xs={2} id="sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col xs={10} id="page-content-wrapper">
            <Toaster />

            <Modal show={showModel} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Success</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                You just approved <b>{approvedUserEmail}</b> successfully.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>

            {pageState.isLoading ? (
              <Container>
                <Row className="justify-content-md-center">
                  <Col xs lg="2"></Col>
                  <Col md="auto">
                    <Container fluid className="py-5">
                      <PulseLoader
                        loading={pageState.isLoading}
                        color="#9B9B9B"
                      />
                    </Container>
                  </Col>
                  <Col xs lg="2"></Col>
                </Row>
              </Container>
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Requested At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {trialRequestedUsers.map(function (user, index) {
                    return (
                      <tr key={index}>
                        <td>{index}</td>
                        <td>{user.user_basic_info.display_name}</td>
                        <td>{user.user_basic_info.email}</td>
                        <td>{user.created_at}</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() => onApproved(user.license_id)}
                          >
                            Approve
                          </Button>
                          <Button variant="outline-danger">Decline</Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
