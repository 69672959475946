import { createAuthProvider } from "react-token-auth";
import { apiUrl } from "../const/base_url";

type Session = { access_token: string; refresh_token: string }; //this key must be same name from api

export const { useAuth, authFetch, login, logout } =
  createAuthProvider<Session>({
    getAccessToken: (session) => session.access_token,
    storage: localStorage,
    onUpdateToken: (token) =>
      fetch(`${apiUrl}/api/v1/refresh`, {
        method: "POST",
        body: token.refresh_token,
      }).then((r) => r.json()),
  });
