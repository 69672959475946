import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import "./App.css";
import { useAuth } from "./auth";
import {
  accountBasedProductsPath,
  accountInfoPath, approvedUsersPath, emailVerificationPath,
  loginPath,
  passwordResetLinkSentPath,
  productDownloadPath,
  resetPasswordPath,
  root,
  signUpPath, trialRequestedUsersPath
} from "./const/routes";
import { PrivateRoute } from "./helpers/PrivateRoute";
import AccountInfoPage from "./pages/AccountInfoPage";
import { ApprovedUsersPage } from "./pages/ApprovedUsersPage";
import { DownloadLinkPage } from "./pages/DownloadLinkPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import Login from "./pages/LoginPage";
import { PasswordResetEmailSentPage } from "./pages/PasswordResetEmailSentPage";
import { PasswordResetPage } from "./pages/PasswordResetPage";
import ProductInfoPage from "./pages/ProductInfoPage";
import SignUp from "./pages/SignUpPage";
import { TrialRequestedUsersPage } from "./pages/TrialRequestedUsersPage";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path={emailVerificationPath}
            element={<EmailVerificationPage />}
          />
          <Route
            path={passwordResetLinkSentPath}
            element={<PasswordResetEmailSentPage />}
          />
          <Route path={productDownloadPath} element={<DownloadLinkPage />} />
          <Route
            path={accountInfoPath}
            element={<PrivateRoute component={AccountInfoPage} />}
          />
          <Route
            path={accountBasedProductsPath}
            element={<ProductInfoPage />}
          />
          <Route path={resetPasswordPath} element={<PasswordResetPage />} />
          <Route path={signUpPath} element={<SignUp />} />
          <Route path={loginPath} element={<Login />} />
          <Route path={root} element={<Home />} />
          <Route path={trialRequestedUsersPath} element={<TrialRequestedUsersPage />}/>
          <Route path={approvedUsersPath} element={<ApprovedUsersPage />} />
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  const [logged] = useAuth();

  if (logged) {
    return <Navigate to={{ pathname: accountInfoPath }} />;
  } else {
    return <Navigate to={{ pathname: loginPath }} />;
  }
}

export default App;
