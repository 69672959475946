import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { accountInfoPath, loginPath, trialRequestedUsersPath } from "../const/routes";
import { hasToken, isAdmin, removeTokens } from "../services/LocalStroage";

function NavTabs() {
  const isLoggedIn = hasToken();
  const navigate = useNavigate();

  const onLogoutClicked = (e: any) => {
    e.preventDefault();
    removeTokens();
    navigate(loginPath, { replace: true });
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href={accountInfoPath}>Async2Secure</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            {isLoggedIn ? (
              <NavDropdown
                id="basic-nav-dropdown"
                title={
                  <div>
                    <FaUserCircle size={30} />
                  </div>
                }
              >
                <NavDropdown.Item href={accountInfoPath}>Account Info</NavDropdown.Item>
                <div>
                  {isAdmin() ? (
                    <div>
                      <NavDropdown.Item href={trialRequestedUsersPath}>Admin Panel</NavDropdown.Item>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <NavDropdown.Item href="/account/products">Products</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={onLogoutClicked}>Sign Out</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <div></div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavTabs;
