import "bootstrap/dist/css/bootstrap.min.css";
import { Field, Form, Formik } from "formik";
import { isSome } from "fp-ts/lib/Option";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import NavTabs from "../components/NavBar";
import { accountInfoPath, resetPasswordPath, signUpPath } from "../const/routes";
import { clearState, loginUser } from "../redux/LoginSlice";
import { RootState } from "../redux/store";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Must be a valid email").required("Required"),
  password: Yup.string().required("Required"),
});

function Login() {
  const signInButtonState = useSelector((state: RootState) => state.login.isToDisableSignInButton);
  const signInButtonText = useSelector((state: RootState) => state.login.signInButtonText);
  const errorMessage = useSelector((state: RootState) => state.login.errorMessage);
  const isError = useSelector((state: RootState) => state.login.isError);
  const isSuccess = useSelector((state: RootState) => state.login.isSuccess);
  const redirectWithData = useSelector((state: RootState) => state.login.redirectWithData);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      if (isSome(redirectWithData)) {
        const redirectToOrNone = redirectWithData.value.redirectTo;
        if (isSome(redirectToOrNone)) {
          if (isSome(redirectWithData.value.data)) {
            const email = redirectWithData.value.data.value;
            navigate(redirectToOrNone.value, { state: email, replace: true });
          } else {
            navigate(redirectToOrNone.value, { replace: true });
          }
        }
      }

      toast.error(errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState()); // we need to clear so that it will not in loop when coming from account/info
      navigate(accountInfoPath, { replace: true });
    }
  }, [isError, isSuccess, navigate, errorMessage, dispatch, redirectWithData]);

  return (
    <div>
      <NavTabs />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          dispatch(loginUser({ email: values.email, password: values.password }));
        }}
      >
        {({ errors, touched, values, handleChange }) => (
          <div>
            <div className="form-wrapper">
              <Container>
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <h4>Sign in to Async2Secure</h4>
                  <br></br>
                  <br></br>
                  <Toaster />
                </div>

                <Form>
                  <label htmlFor="email" style={{ display: "block" }}>
                    Email
                  </label>
                  <Field name="email" type="email" />

                  {errors.email && touched.email ? <div className="text-danger">{errors.email}</div> : null}

                  <br></br>

                  <div className="form-group">
                    <label>Password</label>
                    <Field type="password" name="password" onChange={handleChange} value={values.password} />
                    {errors.password ? <div className="text-danger">{errors.password}</div> : null}
                  </div>

                  <div className="text-right  mt-4">
                    <button type="submit" className="btn btn-primary" disabled={signInButtonState}>
                      {signInButtonText}
                    </button>
                  </div>
                </Form>
              </Container>
              <Container className="mt-4">
                <Link to={resetPasswordPath}>I forgot my password</Link>
                <br></br>
                <Link to={signUpPath}>I want to create an account</Link>
              </Container>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default Login;
