import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isRight } from "fp-ts/lib/Either";
import { none, Option, some } from "fp-ts/lib/Option";
import { authFetch } from "../services/Fetch";
import { ProductTrialResponse, ProductTrialStatus } from "../shared/responses";
import {
  ErrorResponse,
  HttpCode
} from "../shared/types";
import { PageState } from "./models";


export const requestProductTrialStatus = createAsyncThunk<
  ProductTrialStatus,
  // First argument to the payload creator
  void,
  // Types for ThunkAPI
  {
    rejectValue: ErrorResponse;
  }
>("/account/products/request/trial", async (_, thunkAPI) => { //turn out "/account/products/productTrial" must be unique
  let result = await authFetch<ProductTrialResponse>({
    url: "api/v1/user/product/trial/sca_toolkit/request",
    method: "post",
    bodyData: none,
  });

  if (isRight(result)) {
    return result.right.data;
  }
  return thunkAPI.rejectWithValue(result.left);
});

// Define a type for the slice state
interface UserAccountInfoState {
  pageState: PageState;
  productTrailStatus: Option<ProductTrialStatus>;
  isAuthorized: boolean;
}

// Define the initial state using that type
const initialState: UserAccountInfoState = {
  isAuthorized: true,
  pageState: { errorMessage: "", isSuccess: false, isError: false, isLoading: false },
  productTrailStatus: none,
};

export const requestProductTrialSlice = createSlice({
  name: "productTrial",
  initialState,
  reducers: {
    clearState: (state) => {
      state.pageState.isError = false;
      state.pageState.isSuccess = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestProductTrialStatus.fulfilled, (state, { payload }) => {
      state.pageState.isLoading = false;
      state.pageState.isSuccess = true;
      state.isAuthorized = true;
      state.pageState.isError = false;
      state.productTrailStatus = some(payload);
    });

    builder.addCase(requestProductTrialStatus.rejected, (state, { payload }) => {
      state.pageState.isError = true;
      state.pageState.isSuccess = false;
      state.pageState.isLoading = false;
      state.pageState.errorMessage = payload ? payload.errorMessage : "Encountered Error";

      if (payload?.httpCode! === HttpCode.UNAUTHORIZED) {
        state.isAuthorized = false;
      }
    });

    builder.addCase(requestProductTrialStatus.pending, (state, _action) => {
      state.pageState.isLoading = true;
    });
  },
});



export const { clearState } = requestProductTrialSlice.actions;
export default requestProductTrialSlice.reducer;
