import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { none, Option, some } from 'fp-ts/lib/Option';
import { apiUrl } from '../const/base_url';
import { emailVerificationPath } from '../const/routes';
import { EmailAddress, RedirectWithData } from '../domain/models';
import { setAdmin, setTokens } from '../services/LocalStroage';
import { EmailVerificationSentResponse } from '../shared/responses';
import { ErrorResponseWithRedirectPath, HttpCode, LoginInfo } from '../shared/types';
// ref: https://redux-toolkit.js.org/usage/usage-with-typescript
export const loginUser = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  any,
  // Types for ThunkAPI
  {
    rejectValue: ErrorResponseWithRedirectPath
  }
>(
  'account/login',
  async (loginInfo: LoginInfo, thunkAPI) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/account/login`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginInfo),
        }
      );
      let jsonResponse = await response.json();
      console.log('response', JSON.stringify(jsonResponse));
      if (response.status === 200) {
        setTokens({ access_token: jsonResponse.data.access_token });
        setAdmin(jsonResponse.data.is_admin);
        return jsonResponse;
      }
      else if (response.status === HttpCode.UNAUTHORIZED) {
        return thunkAPI.rejectWithValue({ errorMessage: "Incorrect username or password", httpCode: HttpCode.UNAUTHORIZED, redirectWithData: none });
      }
      else if (response.status === HttpCode.FORBIDDEN) {
        const response: EmailVerificationSentResponse = jsonResponse;
        if (response.status === "email verification has been sent") {
          const email: EmailAddress = { value: response.data.email }
          const redirectWithData: RedirectWithData = { redirectTo: some(emailVerificationPath), data: some(email) }
          return thunkAPI.rejectWithValue({ errorMessage: "Email verfication has been sent", httpCode: HttpCode.UNAUTHORIZED, redirectWithData: some(redirectWithData) });
        }
        return thunkAPI.rejectWithValue({ errorMessage: "Unknown status", httpCode: HttpCode.UNAUTHORIZED, redirectWithData: none });

      }

      else {
        return thunkAPI.rejectWithValue({ errorMessage: "Encounted Error", httpCode: HttpCode.SERVER_ERROR, redirectWithData: none });
      }
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue({ errorMessage: "Encounted Error", httpCode: HttpCode.SERVER_ERROR, redirectWithData: none });
    }
  }
);

// Define a type for the slice state
interface SigninInState {
  isToDisableSignInButton: boolean,
  signInButtonText: string
  errorMessage: string
  isSuccess: boolean
  isError: boolean
  redirectWithData: Option<RedirectWithData>
}

// Define the initial state using that type
const initialState: SigninInState = {
  isToDisableSignInButton: false,
  signInButtonText: "Sign In",
  errorMessage: "",
  isSuccess: false,
  isError: false,
  redirectWithData: none,
}

export const loginSlice = createSlice({
  name: 'login',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      return state;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(loginUser.fulfilled, (state, _action) => {
      console.log("successfully login")
      state.isSuccess = true;
      state.isToDisableSignInButton = false;
      state.signInButtonText = "Sign In";
    })

    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isToDisableSignInButton = false;
      state.signInButtonText = "Sign In";
      state.errorMessage = payload ? payload.errorMessage : "Encounted Error"
      state.isError = true;
      state.redirectWithData = payload ? payload?.redirectWithData : none
    })

    builder.addCase(loginUser.pending, (state, _action) => {
      state.isToDisableSignInButton = true;
      state.signInButtonText = "Signing In";
    })

  }
})

export const { clearState } = loginSlice.actions;
export default loginSlice.reducer
