import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isRight } from "fp-ts/lib/Either";
import { none, Option, some } from "fp-ts/lib/Option";
import { ChangeLicenseRequest as LicenseChangeRequest } from "../domain/models";
import { authFetch } from "../services/Fetch";
import {
  UserBasicInfo,
  UserBasicInfoResponse
} from "../shared/responses";
import { ErrorResponse, HttpCode } from "../shared/types";
import { PageState } from "./models";

export const changeLicense = createAsyncThunk<
  UserBasicInfoResponse,
  LicenseChangeRequest,
  {
    rejectValue: ErrorResponse;
  }
>("/licenses/id/change/license_type", async (request, thunkAPI) => {
  let result = await authFetch<UserBasicInfoResponse>({
    url: `api/v1/admin/licenses/${request.licenseId}`,
    method: "post",
    bodyData: some(request.data),
  });

  if (isRight(result)) {
    return result.right;
  }
  return thunkAPI.rejectWithValue(result.left);
});

// Define a type for the slice state
interface LicenseTypeChangeState {
  pageState: PageState;
  isAuthorized: boolean;
  userBasicInfoOrNone: Option<UserBasicInfo>;
}

// Define the initial state using that type
const initialState: LicenseTypeChangeState = {
  isAuthorized: true,
  pageState: {
    errorMessage: "",
    isSuccess: false,
    isError: false,
    isLoading: false,
  },
  userBasicInfoOrNone: none,
};

export const licenseTypeChangeSlice = createSlice({
  name: "change_license_type",
  initialState,
  reducers: {
    clearState: (state) => {
      state.pageState.isError = false;
      state.pageState.isSuccess = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeLicense.pending, (state, _action) => {
      state.pageState.isLoading = true;
    });

    builder.addCase(
      changeLicense.fulfilled,
      (state, { payload }) => {
        state.pageState.isLoading = false;
        state.pageState.isSuccess = true;
        state.isAuthorized = true;
        state.pageState.isError = false;
        state.userBasicInfoOrNone = some(payload.data);
      }
    );

    builder.addCase(
      changeLicense.rejected,
      (state, { payload }) => {
        state.pageState.isError = true;
        state.pageState.isSuccess = false;
        state.pageState.isLoading = false;
        state.pageState.errorMessage = payload
          ? payload.errorMessage
          : "Encountered Error";

        if (payload?.httpCode! === HttpCode.UNAUTHORIZED) {
          state.isAuthorized = false;
        }
      }
    );
  },
});

export default licenseTypeChangeSlice.reducer;
