import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ChangeLicenseRequest } from "../../domain/models";
import { fromDateToTimestamp } from "../../domain/value_objects/value_object";
import styles from "./CalendarPage.module.css";
import { CurrentModalUserWithLicense } from "./models";

type CalandarModalProps = {
  showModel: boolean;
  user: CurrentModalUserWithLicense;
  onClose: () => void;
  onSubmit: (values: ChangeLicenseRequest) => void;
};

function CalandarModal(prop: CalandarModalProps) {
  const [timestamp, setTimestamp] = useState(fromDateToTimestamp(new Date()));

  return (
    <>
      <Modal show={prop.showModel} onHide={prop.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Expiration Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className={styles.calendar}>
            <Calendar onChange={(b: Date) => setTimestamp(fromDateToTimestamp(b))} value={new Date()} />
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={prop.onClose}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={() =>
              prop.onSubmit({ licenseId: prop.user.licenseInfo.licenseId, data: { expired_at: timestamp } })
            }
          >
            Change
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CalandarModal;
