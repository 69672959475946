import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import NavTabs from "../components/NavBar";
import { loginPath } from "../const/routes";
import { fetchProductDownloadLinkList } from "../redux/ProductDownloadLinkSlice";
import { RootState } from "../redux/store";
import { removeTokens } from "../services/LocalStroage";

export const DownloadLinkPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageState = useSelector((state: RootState) => state.productDownloadReducer.pageState);
  const isAuthorized = useSelector((state: RootState) => state.productDownloadReducer.isAuthorized);
  const productDownloadLink = useSelector((state: RootState) => state.productDownloadReducer.productDownloadLink);

  const { name } = useParams();
  console.log("type", name);

  useEffect(() => {
    console.log("user effct", name);

    dispatch(fetchProductDownloadLinkList({ product: name! }));

    if (!isAuthorized) {
      removeTokens();
      navigate(loginPath, { replace: true });
    }

    if (pageState.isError) {
      toast.error(pageState.errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
    }
  }, [isAuthorized, pageState.isSuccess]);

  return (
    <div>
      <NavTabs />
      <Toaster />
      <div className="container-fluid mt-5 mb-3">
        {pageState.isLoading ? (
          <Container fluid className="py-5">
            <PulseLoader loading={pageState.isLoading} color="#9B9B9B" />
          </Container>
        ) : (
          <></>
        )}
        {pageState.isSuccess && !pageState.isLoading ? (
          <>
            {productDownloadLink.map((v) => (
              <div>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href={v.build_version_and_link.WOGPU}>
                      {v.version} - Windows (exe)
                    </a>
                  </li>
                </ul>
              </div>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
