import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import NavTabs from "../components/NavBar";
import { loginPath } from "../const/routes";
import { RootState } from "../redux/store";
import { fetchUserAccountInfo } from "../redux/UserAccountInfoSlice";
import { removeTokens } from "../services/LocalStroage";

function AccountInfoPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector((state: RootState) => state.userAccountInfo.errorMessage);
  const isError = useSelector((state: RootState) => state.userAccountInfo.isError);
  const isAuthorized = useSelector((state: RootState) => state.userAccountInfo.isAuthorized);
  const isSuccess = useSelector((state: RootState) => state.userAccountInfo.isSuccess);
  const userAccoutnInfoState = useSelector((state: RootState) => state.userAccountInfo.userAccountInfo);
  const isLoading = useSelector((state: RootState) => state.userAccountInfo.isLoading);

  useEffect(() => {
    //TODO: not sure why dummy parameter is need here
    dispatch(fetchUserAccountInfo());

    if (!isAuthorized) {
      removeTokens();
      navigate(loginPath, { replace: true });
    }

    if (isError) {
      toast.error(errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
    }
  }, [navigate, dispatch, errorMessage, isError, isAuthorized]);

  return (
    <div>
      <NavTabs />
      <Toaster />
      <div className="container-fluid">
        <p className="mt-5 mb-3 h3">Account Info</p>
        <div className="card">
          <div className="card-body">
            {isLoading ? (
              <Container fluid className="py-5">
                <PulseLoader loading={isLoading} color="#9B9B9B" />
              </Container>
            ) : (
              <></>
            )}

            {isSuccess && !isLoading ? (
              <>
                <p>Display Name: {userAccoutnInfoState.name}</p>
                <p>Email Address: {userAccoutnInfoState.email}</p>
                <p>Company Name: {userAccoutnInfoState.company}</p>
                <p>Country: {userAccoutnInfoState.country}</p>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountInfoPage;
