
export const accountInfoPath = "/account/info";
export const emailVerificationPath = "/email_verification";
export const passwordResetLinkSentPath = "/password_reset_link_sent";
export const productDownloadPath = "/account/product/download/:name";
export const accountBasedProductsPath = "/account/products";
export const resetPasswordPath = "/account/reset_password";
export const signUpPath = "/signup";
export const loginPath = "/login";
export const trialRequestedUsersPath = "/admin/trial/users/sca_toolkit";
export const approvedUsersPath = "/admin/approved/users/sca_toolkit";
export const root = "/";

export const buildProductDownloadPath = (productName: string) => `/account/product/download/${productName}`;
