import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isRight } from "fp-ts/lib/Either";
import { some } from "fp-ts/lib/Option";
import { authFetch } from "../services/Fetch";
import { ProductDownloadLinkQuery } from "../shared/queries";
import { BuildVersionAndLinkList, ProductDownloadLinkResponse } from "../shared/responses";
import {
    ErrorResponse,
    HttpCode
} from "../shared/types";
import { PageState } from "./models";


export const fetchProductDownloadLinkList = createAsyncThunk<
    ProductDownloadLinkResponse,
    // First argument to the payload creator
    ProductDownloadLinkQuery,
    // Types for ThunkAPI
    {
        rejectValue: ErrorResponse;
    }
>("/account/products/download_link", async (query, thunkAPI) => {
    console.log("staring")

    let result = await authFetch<ProductDownloadLinkResponse>({
        url: "api/v1/user/product/download_link",
        method: "post",
        bodyData: some(query),
    });

    if (isRight(result)) {
        return result.right;
    }
    console.log("left", result.left)
    return thunkAPI.rejectWithValue(result.left);
});

// Define a type for the slice state
interface State {
    pageState: PageState;
    productDownloadLink: BuildVersionAndLinkList[];
    isAuthorized: boolean;
}

// Define the initial state using that type
const initialState: State = {
    isAuthorized: true,
    pageState: { errorMessage: "", isSuccess: false, isError: false, isLoading: false },
    productDownloadLink: [],
};

export const productDownloadSlice = createSlice({
    name: "productInfos",
    initialState,
    reducers: {
        clearState: (state) => {
            state.pageState.isError = false;
            state.pageState.isSuccess = false;
            return state;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(fetchProductDownloadLinkList.pending, (state, _action) => {
            state.pageState.isLoading = true;
        });

        builder.addCase(fetchProductDownloadLinkList.fulfilled, (state, { payload }) => {
            state.pageState.isLoading = false;
            state.pageState.isSuccess = true;
            state.isAuthorized = true;
            state.pageState.isError = false;
            state.productDownloadLink = payload.data
        });

        builder.addCase(fetchProductDownloadLinkList.rejected, (state, { payload }) => {
            state.pageState.isError = true;
            state.pageState.isSuccess = false;
            state.pageState.isLoading = false;
            state.pageState.errorMessage = payload ? payload.errorMessage : "Encountered Error";

            if (payload?.httpCode! === HttpCode.UNAUTHORIZED) {
                state.isAuthorized = false;
            }
        });
    },
});

export const { clearState } = productDownloadSlice.actions;
export default productDownloadSlice.reducer;
