import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import { EmailAddress } from "../domain/models";



export const PasswordResetEmailSentPage: React.FC = () => {
  const {state} = useLocation();
  const email = state as EmailAddress;
  return (
    <div>
      <div className="form-wrapper">
        <div>
          Password reset email sent to <b>{email.value}</b>
        </div>
        <br></br>
        <div>
          If you don’t see this email in your inbox within 15 minutes, look for
          it in your junk mail folder. If you find it there, please mark it as
          “Not Junk”.
        </div>
      </div>
    </div>
  );
};
