import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { pipe } from 'fp-ts/lib/function';
import { toNullable } from 'fp-ts/lib/Option';
import { apiUrl } from '../const/base_url';
import { getAccessToken, hasToken } from '../services/LocalStroage';
import { ErrorResponse, HttpCode } from '../shared/types';

interface UserAccountInfo {
    name: string
    email: string
    company: string
    country: string
}

export const fetchUserAccountInfo = createAsyncThunk<
    UserAccountInfo,
    // First argument to the payload creator
    void,
    // Types for ThunkAPI
    {
        rejectValue: ErrorResponse
    }>(
        '/account/info',
        async (_, thunkAPI) => {
            try {
                if (!hasToken()) {
                    throw new Error("having error");
                }

                let token = pipe(getAccessToken(), toNullable)!;

                console.log(token.access_token);

                const response = await fetch(
                    `${apiUrl}/api/v1/user/info`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            Authorization: "Bearer " + token.access_token,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                let jsonResponse = await response.json();
                console.log('response', jsonResponse);
                if (response.status === 200) {

                    console.log(jsonResponse.data as UserAccountInfo);

                    return jsonResponse.data as UserAccountInfo;
                }
                else if (response.status === HttpCode.UNAUTHORIZED) {
                    return thunkAPI.rejectWithValue({ errorMessage: "You are not authorized", httpCode: HttpCode.UNAUTHORIZED });
                }
                else if (response.status === HttpCode.SERVER_ERROR) {
                    return thunkAPI.rejectWithValue({ errorMessage: "Encounted Server Error, Please kindly contact us.", httpCode: HttpCode.SERVER_ERROR });
                }

                else {
                    return thunkAPI.rejectWithValue({ errorMessage: "Server Error", httpCode: HttpCode.NOT_FOUND });
                }
            } catch (e) {
                return thunkAPI.rejectWithValue({ errorMessage: "Unable to fetch data", httpCode: HttpCode.NOT_FOUND });
            }
        }
    );


// Define a type for the slice state
interface UserAccountInfoState {
    errorMessage: string
    isSuccess: boolean
    isError: boolean
    isLoading: boolean
    userAccountInfo: UserAccountInfo
    isAuthorized: boolean
}

// Define the initial state using that type
const initialState: UserAccountInfoState = {
    isAuthorized: true,
    errorMessage: "",
    isSuccess: false,
    isError: false,
    isLoading: false,
    userAccountInfo: { name: "", email: "", company: "", country: "" }

}

export const userAccountInfoSlice = createSlice({
    name: 'userAccountInfo',
    initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            return state;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(fetchUserAccountInfo.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isAuthorized = true;
            state.isError = false;
            state.userAccountInfo = {
                email: payload.email,
                name: payload.name,
                country: payload.country,
                company: payload.company
            }
        })

        builder.addCase(fetchUserAccountInfo.rejected, (state, { payload }) => {
            state.isError = true;
            state.isSuccess = false;
            state.isLoading = false;
            state.errorMessage = payload ? payload.errorMessage : "Encountered Error"
            console.log(state.isError)
            console.log(payload?.errorMessage)

            if (payload?.httpCode! === HttpCode.UNAUTHORIZED) {
                state.isAuthorized = false
            }

        }
        );

        builder.addCase(fetchUserAccountInfo.pending, (state, _action) => {
            state.isLoading = true;
        })

    }
})

export const { clearState } = userAccountInfoSlice.actions;
export default userAccountInfoSlice.reducer
