import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Alert, Container } from "react-bootstrap";
import { CountryDropdown } from "react-country-region-selector";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import NavTabs from "../components/NavBar";
import { apiUrl } from "../const/base_url";
import { loginPath } from "../const/routes";
import { HttpCode } from "../shared/types";

const SignupSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email address is required"),
  company: Yup.string() // this 'company' must be same as params difined in 'initialValues'
    .min(2, "Too Short!")
    .required("Company name is required"),
  country: Yup.string().required("Country or Area is required"),
  password: Yup.string().required("Password is required").min(8, "Password is too short - should be 8 chars minimum."),
  confirmpassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

function Signup() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isAccountCreated, setAccountCreated] = useState(false);

  return (
    <div>
      <NavTabs />
      {!isAccountCreated ? (
        <Formik
          initialValues={{
            name: "",
            email: "",
            company: "",
            country: "",
            password: "",
            confirmpassword: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            console.log(values);
            let bodyContent = JSON.stringify(values);

            fetch(`${apiUrl}/api/v1/account/register`, {
              method: "post",
              body: bodyContent,
            })
              .then((response) => {
                if (response.ok || response.status === 400 || response.status === HttpCode.CONFLICT) {
                  return response.json();
                } else {
                  throw new Error("something");
                }
              })
              .then((jsonValue) => {
                if (jsonValue.data) {
                  setErrorMessage("");
                  setAccountCreated(true);
                } else {
                  if (jsonValue.error) {
                    setErrorMessage("The account with the given email address already exists.");
                  }
                }
              })
              .catch((error) => {
                setErrorMessage("We encounter unknown error");
              });
          }}
        >
          {({ errors, touched, values, handleChange }) => (
            <div className="form-wrapper">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <h4>Account Registration</h4>
                {errorMessage && <Alert variant="danger"> {errorMessage} </Alert>}

                <Container>
                  <Form>
                    <div className="form-group">
                      <label htmlFor="Name" style={{ display: "block" }}>
                        Name
                      </label>
                      <Field name="name" />

                      {errors.name && touched.name ? <div className="text-danger">{errors.name}</div> : null}
                    </div>

                    <div className="form-group">
                      <label htmlFor="email" style={{ display: "block" }}>
                        Email
                      </label>
                      <Field name="email" type="email" />

                      {errors.email && touched.email ? <div className="text-danger">{errors.email}</div> : null}
                    </div>

                    <div className="form-group">
                      <label htmlFor="company_name" style={{ display: "block" }}>
                        Company Name
                      </label>
                      <Field name="company" />

                      {errors.company && touched.company ? <div className="text-danger">{errors.company}</div> : null}
                    </div>

                    <label htmlFor="country" style={{ display: "block" }}>
                      Country or Area
                    </label>
                    <div className="formRow checkoutInput">
                      <CountryDropdown name="country" value={values.country} onChange={(_, e) => handleChange(e)} />
                    </div>
                    {errors.country && touched.country ? <div className="text-danger">{errors.country}</div> : null}

                    <br></br>

                    <div className="form-group">
                      <label>Password:</label>
                      <Field type="password" name="password" onChange={handleChange} value={values.password} />
                      {errors.password ? <div className="text-danger">{errors.password}</div> : null}
                    </div>
                    {/* passwod */}

                    {/* confrm password */}
                    <div className="form-group">
                      <label>Confirm Password:</label>
                      <Field
                        type="password"
                        name="confirmpassword"
                        onChange={handleChange}
                        value={values.confirmpassword}
                      />
                      {errors.confirmpassword ? <div className="text-danger">{errors.confirmpassword}</div> : null}
                    </div>

                    <div className="text-right mt-4">
                      <button type="submit" className="btn btn-primary">
                        Register
                      </button>
                    </div>
                  </Form>
                </Container>
                <Link className="mt-4" to={loginPath}>
                  Sign into an existing account{" "}
                </Link>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div>
          <div className="form-wrapper">
            <Alert variant="primary"> ACCOUNT SUCCESSFULLY CREATED! </Alert>
            <p>
              To complete your account registration, please check your email for{" "}
              <mark className="text-success">a confirmation link</mark>. If you don't see it, you may need to{" "}
              <b>check your spam</b> folder.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Signup;
