import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isRight } from "fp-ts/lib/Either";
import { none, Option, some } from "fp-ts/lib/Option";
import { LicenseApprovalRequest } from "../domain/models";
import { authFetch } from "../services/Fetch";
import {
  TrialApproval,
  TrialApprovalResponse,
  TrialRequestedUser,
  TrialRequestedUsersResponse
} from "../shared/responses";
import { ErrorResponse, HttpCode } from "../shared/types";
import { PageState } from "./models";

export const fetchApprovedUsers = createAsyncThunk<
  TrialRequestedUsersResponse,
  // First argument to the payload creator
  void,
  // Types for ThunkAPI
  {
    rejectValue: ErrorResponse;
  }
>("/admin/users/trial/requested", async (_, thunkAPI) => {
  console.log("staring");

  let result = await authFetch<TrialRequestedUsersResponse>({
    url: "api/v1/admin/users/approved/sca_toolkit",
    method: "get",
    bodyData: none,
  });

  if (isRight(result)) {
    return result.right;
  }
  console.log("left", result.left);
  return thunkAPI.rejectWithValue(result.left);
});

export const approveTrialRequestedUser = createAsyncThunk<
  TrialApprovalResponse,
  // First argument to the payload creator
  LicenseApprovalRequest,
  // Types for ThunkAPI
  {
    rejectValue: ErrorResponse;
  }
>("/admin/users/trial/approved", async (licenseApprovalRequest, thunkAPI) => {
  let result = await authFetch<TrialApprovalResponse>({
    url: `api/v1/admin/approve/trial/${licenseApprovalRequest.license_id}`,
    method: "post",
    bodyData: some(licenseApprovalRequest),
  });

  if (isRight(result)) {
    return result.right;
  }
  return thunkAPI.rejectWithValue(result.left);
});

// Define a type for the slice state
interface TrialRequestedUsersState {
  pageState: PageState;
  trialRequestedUsers: TrialRequestedUser[];
  isAuthorized: boolean;
  trialApprovalState: Option<TrialApproval>;
}

// Define the initial state using that type
const initialState: TrialRequestedUsersState = {
  isAuthorized: true,
  pageState: {
    errorMessage: "",
    isSuccess: false,
    isError: false,
    isLoading: false,
  },
  trialRequestedUsers: [],
  trialApprovalState: none,
};

export const approvedUsersSlice = createSlice({
  name: "productTrial",
  initialState,
  reducers: {
    clearState: (state) => {
      state.pageState.isError = false;
      state.pageState.isSuccess = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApprovedUsers.fulfilled, (state, { payload }) => {
      state.pageState.isLoading = false;
      state.pageState.isSuccess = true;
      state.isAuthorized = true;
      state.pageState.isError = false;
      state.trialRequestedUsers = payload.data;
    });

    builder.addCase(fetchApprovedUsers.rejected, (state, { payload }) => {
      state.pageState.isError = true;
      state.pageState.isSuccess = false;
      state.pageState.isLoading = false;
      state.pageState.errorMessage = payload
        ? payload.errorMessage
        : "Encountered Error";

      if (payload?.httpCode! === HttpCode.UNAUTHORIZED) {
        state.isAuthorized = false;
      }
    });

    builder.addCase(fetchApprovedUsers.pending, (state, _action) => {
      state.pageState.isLoading = true;
    });

    builder.addCase(approveTrialRequestedUser.pending, (state, _action) => {
      state.pageState.isLoading = true;
    });

    builder.addCase(
      approveTrialRequestedUser.fulfilled,
      (state, { payload }) => {
        state.pageState.isLoading = false;
        state.pageState.isSuccess = true;
        state.isAuthorized = true;
        state.pageState.isError = false;
        state.trialApprovalState = some(payload.data);
      }
    );

    builder.addCase(
      approveTrialRequestedUser.rejected,
      (state, { payload }) => {
        state.pageState.isError = true;
        state.pageState.isSuccess = false;
        state.pageState.isLoading = false;
        state.pageState.errorMessage = payload
          ? payload.errorMessage
          : "Encountered Error";

        if (payload?.httpCode! === HttpCode.UNAUTHORIZED) {
          state.isAuthorized = false;
        }
      }
    );
  },
});

export default approvedUsersSlice.reducer;
