import { isSome, none, Option, some } from "fp-ts/Option";
import { Token } from "../shared/types";

export const setTokens = (token: Token) =>
  localStorage.setItem("access_token", token.access_token);
export const removeTokens = () => localStorage.removeItem("access_token");
export const getAccessToken = (): Option<Token> => {
  let token = localStorage.getItem("access_token");
  return token ? some({ access_token: token }) : none;
};

export const hasToken = () => isSome(getAccessToken());

export const setAdmin = (isAdmin: boolean) =>
  localStorage.setItem("is_admin", isAdmin.toString());
export const isAdmin = (): boolean => {
  let v = localStorage.getItem("is_admin");
  return v === "true";
};
