import "bootstrap/dist/css/bootstrap.min.css";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import NavTabs from "../components/NavBar";
import { apiUrl } from "../const/base_url";
import { loginPath, passwordResetLinkSentPath } from "../const/routes";
import { EmailAddress } from "../domain/models";
import { PasswordResetSentResponse } from "../shared/responses";
import { HttpCode } from "../shared/types";

export const PasswordResetPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").required("Required"),
  });

  return (
    <div>
      <NavTabs />
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          let bodyContent = JSON.stringify(values);

          fetch(`${apiUrl}/api/v1/account/reset_password`, {
            method: "post",
            body: bodyContent,
          })
            .then((r) => r.json().then((data) => ({ status: r.status, json: data })))
            .then((obj) => {
              if (obj.status === HttpCode.OK) {
                const response: PasswordResetSentResponse = obj.json;
                const email: EmailAddress = { value: response.data.email };
                navigate(passwordResetLinkSentPath, { state: email });
              } else if (obj.status === HttpCode.NOT_FOUND) {
                setErrorMessage("Email not found.");
              } else {
                setErrorMessage("We encounter unknown error.");
              }
            })
            .catch((err) => setErrorMessage("We encounter unknown error"));
        }}
      >
        {({ errors, touched, values, handleChange }) => (
          <div className="form-wrapper">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <h4>Reset your Password</h4>
            </div>

            <div className="row d-flex justify-content-center align-items-center h-100">
              <br></br>
              <br></br>
              <div>{errorMessage && <Alert variant="danger"> {errorMessage} </Alert>}</div>
            </div>

            <Form>
              <label htmlFor="email" style={{ display: "block" }}>
                Email
              </label>
              <Field name="email" type="email" />

              {errors.email && touched.email ? <div className="text-danger">{errors.email}</div> : null}

              <br></br>
              <div>
                <div className="text-right">
                  <button type="submit" className="btn btn-primary">
                    Send a reset link
                  </button>
                </div>

                <div className="text-right">
                  <Link to={loginPath}>Cancel</Link>
                </div>
              </div>
            </Form>
          </div>
          // </div>
        )}
      </Formik>
    </div>
  );
};
