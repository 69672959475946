import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './LoginSlice'
import productInfos from './ProductsInfoSlice'
import userAccountInfoReducer from './UserAccountInfoSlice'
import productDownloadReducer from './ProductDownloadLinkSlice'
import productTrialStatusReducer  from './TrialStatusSlice'
import requestProductTrialStatusReducer  from './RequestProductTrial'
import trialRequestedUsersReducer from './TrialRequestedUsersSlice'
import approvedUserReducer from './ApprovedUsersSlice'
import changeLicenseTypeReducer from './UpdateLicenseSlice'
export const store = configureStore({
  reducer: {
    login: loginReducer,
    userAccountInfo: userAccountInfoReducer,
    productsInfo: productInfos,
    productDownloadReducer: productDownloadReducer,
    productTrialStatusReducer: productTrialStatusReducer,
    productTrialRequestReducer: requestProductTrialStatusReducer,
    trialRequestedUsersReducer: trialRequestedUsersReducer,
    approvedUserReducer: approvedUserReducer,
    changeLicenseTypeReducer: changeLicenseTypeReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
