import { isSome, Option } from "fp-ts/lib/Option";
import { useEffect } from "react";
import { Button, Card, Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { requestProductTrialStatus } from "../redux/RequestProductTrial";
import { RootState } from "../redux/store";
import { fetchProductTrialStatus } from "../redux/TrialStatusSlice";
import { ProductTrialStatus } from "../shared/responses";

function RequestSideChannelToolKitComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trialStatuspageState = useSelector((state: RootState) => state.productTrialStatusReducer.pageState);
  const requestTrialPageState = useSelector((state: RootState) => state.productTrialRequestReducer.pageState);
  const appTrialState = useSelector((state: RootState) => state.productTrialStatusReducer.productTrailStatus);

  function onRequestScaToolkitFreeTrial() {
    dispatch(requestProductTrialStatus());
  }

  useEffect(() => {
    if (requestTrialPageState.isError) {
      toast.error(trialStatuspageState.errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
    }
    if (requestTrialPageState.isSuccess) {
      toast.success("Your software trail request has been successful.");
    }
  }, [requestTrialPageState]);

  useEffect(() => {
    dispatch(fetchProductTrialStatus());

    if (trialStatuspageState.isError) {
      toast.error(trialStatuspageState.errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
    }
  }, [dispatch, navigate, trialStatuspageState.errorMessage, trialStatuspageState.isError, requestTrialPageState]);

  function getCardOnTrialState(v: Option<ProductTrialStatus>) {
    if (isSome(v)) {
      return (
        <>
          <Card.Title></Card.Title>
          <Card.Text>
            Your request is received at {v.value.requested_at}. We will notify you when the application is ready to use.
            Thank you.
          </Card.Text>
        </>
      );
    } else {
      return (
        <>
          <Card.Title></Card.Title>
          <Card.Text>
            The side-channel analysis software tool can easily quantify and qualify an hardware from side-channel
            leakages.
          </Card.Text>
          <Button variant="primary" onClick={() => onRequestScaToolkitFreeTrial()}>
            Request For Trial
          </Button>
        </>
      );
    }
  }

  return (
    <Card style={{ width: "40rem" }} className="mt-5">
      <Card.Header>Free Trial - Side Channel Attack Toolkit</Card.Header>
      <Toaster />
      {trialStatuspageState.isLoading || requestTrialPageState.isLoading ? (
        <Card.Body>
          <Container fluid className="py-5">
            <PulseLoader loading={trialStatuspageState.isLoading || requestTrialPageState.isLoading} color="#9B9B9B" />
          </Container>
        </Card.Body>
      ) : (
        <Card.Body>
          <>{getCardOnTrialState(appTrialState)}</>
        </Card.Body>
      )}
    </Card>
  );
}

export default RequestSideChannelToolKitComponent;
