import assert from "assert";
import { Either, left, right } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import { getOrElse, isNone, toNullable } from "fp-ts/lib/Option";
import { apiUrl } from "../const/base_url";
import { ErrorResponse, FetchParameter, HttpCode } from "../shared/types";
import { getAccessToken, hasToken } from "./LocalStroage";

export async function authFetch<T>(
  params: FetchParameter
): Promise<Either<ErrorResponse, T>> {

  if (params.method === "get") {
    assert(isNone(params.bodyData));
  }

  if (!hasToken()) {
    return left({ errorMessage: "no token", httpCode: HttpCode.UNAUTHORIZED });
  }

  let token = pipe(getAccessToken(), toNullable)!;

  try {
    let bodyData = pipe(
      params.bodyData,
      getOrElse(() => null)
    );

    const response = await fetch(`${apiUrl}/${params.url}`, {
      method: params.method,
      headers: {
        Authorization: "Bearer " + token.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: isNone(params.bodyData) ? null : JSON.stringify(bodyData),
    });

    if (response.status === HttpCode.OK) {
      let jsonResponse = await response.json();
      console.log("json received", JSON.stringify(jsonResponse));
      return right(jsonResponse);
    } else {
      return left({ errorMessage: "error", httpCode: response.status });
    }
  } catch (e) {
    return left({
      errorMessage: "Unable to fetch data",
      httpCode: HttpCode.NOT_FOUND,
    });
  }
}
