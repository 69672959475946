import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useLocation } from "react-router-dom";
import NavTabs from "../components/NavBar";
import { EmailAddress } from "../domain/models";

function EmailVerificationPage() {
  const { state } = useLocation();
  const email = state as EmailAddress;

  console.log("email ver", state);

  return (
    <div>
      <NavTabs />
      <div className="form-wrapper">
        <p className="text-center">
          <h4>Please verify your email</h4>
          You’re almost there! We sent an email to
        </p>
        <p className="text-center">
          <b>{email.value}</b>
        </p>

        <div className="row d-flex justify-content-center align-items-center h-100">
          <p className="text-center">
            Just click on the link in that email to complete your signup. If you don't see it, you may need to{" "}
            <b>check your spam</b> folder.
          </p>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationPage;
