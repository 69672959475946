import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ChangeLicenseRequest } from "../domain/models";

type InfoModalProps = {
  showModel: boolean;
  info: string;
  onClose: () => void;
  onSubmit: (values: ChangeLicenseRequest) => void;
};

function InfoModal(prop: InfoModalProps) {
  return (
    <>
      <Modal show={prop.showModel} onHide={prop.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>{prop.info}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={prop.onClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default InfoModal;
