import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import NavTabs from "../components/NavBar";
import RequestSideChannelToolKitComponent from "../components/RequestSideChannelTookKitComponent";
import { buildProductDownloadPath } from "../const/routes";
import { fetchProductInfoList } from "../redux/ProductsInfoSlice";
import { RootState } from "../redux/store";
import { removeTokens } from "../services/LocalStroage";

function ProductInfoPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageState = useSelector(
    (state: RootState) => state.productsInfo.pageState
  );
  const isAuthorized = useSelector(
    (state: RootState) => state.productsInfo.isAuthorized
  );
  const productInfoList = useSelector(
    (state: RootState) => state.productsInfo.applicaitonInfoList
  );

  function onDownloadButtonClicked(
    productName: string,
    productQueryName: string
  ) {
    navigate(buildProductDownloadPath(productQueryName));
  }

  useEffect(() => {
    dispatch(fetchProductInfoList());

    if (!isAuthorized) {
      removeTokens();
      navigate("/login", { replace: true });
    }

    if (pageState.isError) {
      toast.error(pageState.errorMessage, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#993344",
        },
        iconTheme: {
          primary: "#993344",
          secondary: "#FFFAEE",
        },
      });
    }
  }, [
    dispatch,
    isAuthorized,
    navigate,
    pageState.errorMessage,
    pageState.isError,
  ]);

  return (
    <div>
      <NavTabs />
      <div className="container-fluid mt-5 mb-3">
        <h3>Products</h3>
        {pageState.errorMessage && (
          <Alert variant="danger"> {pageState.errorMessage} </Alert>
        )}
        <div style={{ width: "40rem" }} className="card">
          <div className="card-body">
            {pageState.isLoading ? (
              <Container fluid className="py-5">
                <PulseLoader loading={pageState.isLoading} color="#9B9B9B" />
              </Container>
            ) : (
              <></>
            )}

            {pageState.isSuccess &&
            !pageState.isLoading &&
            productInfoList.length === 0 ? (
              <>
                <div>
                  <p> You do not have any products to show. </p>
                </div>
              </>
            ) : (
              <></>
            )}

            {pageState.isSuccess &&
            !pageState.isLoading &&
            productInfoList.length > 0 ? (
              <>
                {productInfoList.length === 0}

                {productInfoList.map((value, index) => {
                  return (
                    <div key={index}>
                      <p> Product Name : {value.app_name} </p>
                      <p> Tier : {value.license_type} </p>
                      <p> Expiration Date : {value.expired_at} </p>

                      <Button
                        variant="primary"
                        onClick={() =>
                          onDownloadButtonClicked(value.app_id, "sca_toolkit")
                        }
                      >
                        Download
                      </Button>
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {pageState.isSuccess &&
        !pageState.isLoading &&
        productInfoList.length === 0 ? (
          <RequestSideChannelToolKitComponent></RequestSideChannelToolKitComponent>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ProductInfoPage;
